import { createWebHistory, createRouter } from "vue-router";
import Login from "@/components/Login.vue";
// lazy-loaded
const Home = () => import("@/components/Home.vue")

let routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  // },
  {
    path: "/login",
    component: Login,
  }
];

let routes_games = {
  keno: {code: 'keno', component_path: 'Keno', component: 'Keno', path: 'keno', name: 'Keno'},
  kenoext: {code: 'kenoext', component_path: 'Kenoext', component: 'Kenoext', path: 'kenoext', name: 'Kenoext'},
  kenoweb: {code: 'kenoweb', component_path: 'Kenoweb', component: 'Kenoweb', path: 'kenoweb', name: 'Kenoweb'},
  cock: {code: 'cock', component_path: 'Cock', component: 'Main', path: 'cock', name: 'Cock'},
  moto: {code: 'moto', component_path: 'Moto', component: 'Main', path: 'moto', name: 'Moto'},
  horse: {code: 'horse', component_path: 'Horse', component: 'Main', path: 'horse', name: 'Horse'},
  dog: {code: 'dog', component_path: 'Dog', component: 'Main', path: 'dog', name: 'Dog'},
  ruleta1: {code: 'ruleta1', component_path: 'Roulette1', component: 'Roulette1', path: 'ruleta1', name: 'Ruleta1'},
  footbolitos: {code: 'footbolitos', component_path: 'Footbolitos', component: 'Footbolitos', path: 'footbolitos', name: 'Footbolitos'},
}

var CompGames = {}

for(let key in routes_games) {
  let cur = routes_games[key]

  if(process.env.VUE_APP_GAME==cur.code || process.env.VUE_APP_GAME=='') {
    CompGames[cur.code] = () => import("@/components/"+cur.component_path+"/"+cur.component+".vue")

    routes.push({
      path: "/"+cur.path,
      name: cur.name,
      // lazy-loaded
      component: CompGames[cur.code]
    })
  }
}

let game_nname = process.env.VUE_APP_GAME;
let maingame = routes_games[game_nname];
routes.push({
  path: "/",
  name: maingame.name,
  // lazy-loaded
  component: CompGames[maingame.code]
})

const router = createRouter({
  history: createWebHistory('#'),
  routes,
});

function parseGET() {
  // Обработка GET-запросов
  var tmp = new Array();    // два вспомогательных
  var tmp2 = new Array();  // массива
  var get = new Array();

  var url = location.search;	// строка GET запроса
  if(url != '') {
    tmp = (url.substr(1)).split('&');	// разделяем переменные
    for(var i=0; i < tmp.length; i++) {
      tmp2 = tmp[i].split('=');		// массив param будет содержать
      get[tmp2[0]] = tmp2[1];		// пары ключ(имя переменной)->значение
    }
  }

  return get;
}

router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  let loggedIn = sessionStorage.getItem('user');
  let params_get = parseGET();

  if(params_get.uid!=undefined && loggedIn!=null) {
    let user_data = JSON.parse(loggedIn);
    if(user_data.token!=undefined && params_get.uid!=user_data.token) {
      loggedIn = null;
    }
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    sessionStorage.setItem('user', '{}')
    next('/login')
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  let title=to.name
  document.title = title;
  /*Vue.nextTick(() => {
    document.title = title;
  });*/
});

export default router;