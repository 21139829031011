<template>
  <div v-if="flags.connect_error==true" class="no-connection" id="connectError"><span id="connectErrorTxt">{{ $t('app.error.connectError') }}<br> {{ $t('app.error.verefyconnection') }}</span></div>
  <div v-if="flags.connect_error==true" class="wrapper mainWrapper"></div>

  <div v-if="flags.connect_error!=true" class="ext_bg">
    <img :src="res_blob_url['ext_bg']">
  </div>

  <div v-if="flags.connect_error!=true" class="wrapper mainWrapper">
    <LoadingLine v-show="flags.state=='loading' || params_get.slide=='loading'" :cnt="res_cnt+1" :cur="cnt_loaded-1"></LoadingLine>
    <router-view/>
  </div>

  <Debug v-if="debug==true && params_get.debug=='main'" :data="debug_data"></Debug>
</template>

<script setup>
import {onMounted, computed, provide, ref, getCurrentInstance, inject, watchEffect, reactive} from "vue";
import {useStore} from "vuex";
import {useRouter, useRoute} from 'vue-router'
import Login from '@/components/Login.vue'
import axios from "axios";
import LoadingLine from "@/components/Public/Loading/LoadingLine.vue";
import Debug from "@/components/Public/Debug/Debug.vue";
import GamesList from "@/components/Public/GamesList/GamesList.vue";

const app_config=inject('app_config')
const setting=app_config
/*const setting={
  game_id: app_config.game_id,
  offset: app_config.offset,
  videosrc: app_config.videosrc
}*/
provide('setting', setting)

const timers=ref({
  real: null,
  offset: null,
  offsetms: null,
  tint: null,
  show: null,
  from: null,
  interval: null
})
provide('timers', timers)

const flags=ref({
  current_event: true,
  current_event_one: true,
  first: true,
  first_round: true,
  first_animation: true,
  first_video: true,
  state_video: '',
  state: 'start',
  statesub: '',
  statesubf: false,
  jackpots_show: false,
  jackpots_shift: false,
  animation: true,
  connect_error: false,
  connect_state: true
})
provide('flags', flags)

function clearStateSub(flags_state=true, callback=null)
{
  flags.value.statesub=''

  flags.value.statesubf=flags_state

  if(callback!=null) {
    callback()
  }
}
provide('clearStateSub', clearStateSub)

function setStateSub(state, time=null, callback)
{
  if(flags.value.statesubf==false) {
    flags.value.statesubf=true

    flags.value.statesub=state

    if(time!=null) {
      setTimeout(clearStateSub, time, true, callback)
    }
  }
}
provide('setStateSub', setStateSub)

const store = useStore()
const router = useRouter()
provide('router', router)

const route = useRoute()
const route_path = computed(() => route.path)
provide('route', route)

const skin=1
provide('skin', skin)

const params_get=parseGET()
provide('params_get', params_get)

const muted=ref(true)
if(params_get.sound=='on') {
  muted.value=false
}
provide('muted', muted)

const debug = ref(false)

const debug_data = ref({})
provide('debug_data', debug_data)

if(params_get.debug=='main') {
  debug.value=true
}

if(params_get.debug=='main2') {
  debug.value=true
}

if(params_get.debug=='v') {
  debug.value=true
}

if(params_get.debug=='jp') {
  debug.value=true
}

if(params_get.debug=='clog') {
  debug.value=true
}

function detectLanguage()
{
  if(params_get.lang!=undefined) {
    return params_get.lang
  }

  const lng = window.navigator.userLanguage || window.navigator.language;
  const locales = require.context(
      "./locales",
      true,
      /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const lang = locales
      .keys()
      .find((key) => lng.includes(key.replace("./", "").replace(".json", "")));

  return lang ? lang.replace("./", "").replace(".json", "") : 'en'
}

function defaultLocale()
{
  $i18n.locale = detectLanguage();
}

function get_last_el_obj(obj)
{
  let lastKey = Object.keys(obj).pop();
  let lastValue = obj[lastKey];

  return lastValue;
}
provide('get_last_el_obj', get_last_el_obj)

const internalInstance = getCurrentInstance()
const globalProperties=internalInstance.appContext.config.globalProperties
const $t=globalProperties.$t
provide('$t', $t)
const $i18n=globalProperties.$i18n
provide('$i18n', $i18n)

const currentUser = computed(() => store.state.auth.user)
provide('currentUser', currentUser)

function isAuthorized_fn()
{
  let currentSession = JSON.parse(sessionStorage.getItem('user'));
  return currentUser.value !== undefined
      && currentUser.value !== null
      && currentUser.value.accessToken !== undefined
      && currentUser.value.accessToken !== null
      && currentUser.value.accessToken !== ''
      && currentSession !== undefined
      && currentSession !== null
      && currentSession.accessToken !== undefined
      && currentSession.accessToken !== null
      && currentSession.accessToken !== '';
}
const isAuthorized=computed(isAuthorized_fn)
provide('isAuthorized', isAuthorized)

function zoomApp()
{
  var winwidth = window.innerWidth;
  var winheight = window.innerHeight;
  var sx = winwidth / 1920;
  var sy = winheight / 1080;

  var	_k = sx>sy ? sy:sx;

  document.querySelector('#app').style.transform = "scale("+_k+")"
  document.querySelector('#app').style.transformOrigin = "left top"
  document.querySelector('#app').style.width = "calc(100%/"+_k+")"
  document.querySelector('#app').style.height = "calc(100%/"+_k+")"
}

function parseGET() {
  // Обработка GET-запросов
  var tmp = new Array();    // два вспомогательных
  var tmp2 = new Array();  // массива
  var get = new Array();

  var url = location.search;	// строка GET запроса
  if(url != '') {
    tmp = (url.substr(1)).split('&');	// разделяем переменные
    for(var i=0; i < tmp.length; i++) {
      tmp2 = tmp[i].split('=');		// массив param будет содержать
      get[tmp2[0]] = tmp2[1];		// пары ключ(имя переменной)->значение
    }
  }

  return get;
}

function formatFloat(data, symbol=' ')
{
  let res=data.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, symbol)

  return res
}
provide('formatFloat', formatFloat)

function compareNumeric(a, b) {
  if (a > b) return 1;
  if (a == b) return 0;
  if (a < b) return -1;
}

function sortStrArrInt(arr) {
  let results_arr_int=[]

  for(let result_key in arr) {
    let result_int=parseInt(arr[result_key])
    results_arr_int.push(result_int)
  }

  results_arr_int.sort(compareNumeric)

  return results_arr_int
}
provide('sortStrArrInt', sortStrArrInt)

function clog() {
  if (debug.value == true) {
    //let args=[]

    let res = ''
    if (arguments.length == 1 && typeof arguments[0] == 'object') {
      res=arguments[0]
    } else {
      for (var i = 0; i < arguments.length; i++) {
        //args[i] = arguments[i];

        res = res + arguments[i] + ' '
      }
      //console.log(arguments[i])
    }

  console.log(res)
  //console.table([args])
  }
}
provide('clog', clog)

function calc_minmax(dt, key_data)
{
  let arr=[]

  for(let key in dt) {
    let cur=dt[key]

    let data=parseFloat(cur)

    if (key_data!==undefined) {
      data=parseFloat(cur[key_data])
    }

    arr.push(data)
  }

  let unique = [...new Set(arr)];
  unique.sort((prev, next)=>prev-next)

  let res={min: [], max: []}

  res.max.push(unique.pop())
  res.min.push(unique.shift())

  return res
}
provide('calc_minmax', calc_minmax)

function calc_minmax2(dt)
{
  let arr=[]

  for(let key in dt) {
    let cur=dt[key]

    for(let key2 in cur) {
      let cur2=cur[key2]

      if(cur2!='-') {
        let data=parseFloat(cur2)
        arr.push(data)
      }
    }
  }

  let unique = [...new Set(arr)];
  unique.sort((prev, next)=>prev-next)

  let res={min: [], max: []}

  res.max.push(unique.pop())
  res.max.push(unique.pop())
  res.min.push(unique.shift())
  res.min.push(unique.shift())

  return res
}
provide('calc_minmax2', calc_minmax2)

function connection_restory()
{
  location.reload()
}
provide('connection_restory', connection_restory)

const connect_error = ref(false)

const isload=ref(false)
provide('isload', isload)

const res_blob_url=ref({})
provide('res_blob_url', res_blob_url)
const res_blob=ref({})
provide('res_blob', res_blob)

const res_cnt=ref(null)
const cnt_loaded=ref(0)
const wt = ref(0)

function timer_char_fn()
{
  let res

  let minutes = Math.floor(timers.value.show / 60);
  let seconds = timers.value.show % 60;

  res=minutes+':'+String(seconds).padStart(2, '0')

  return res
}
provide('timer_char_fn', timer_char_fn)

async function adddImg(key, url)
{
  res_blob.value[key] = url

  wt.value++
}

async function adddImgUrl(key, blob)
{
  const blobUrl = URL.createObjectURL(blob);

  res_blob_url.value[key] = blobUrl

  wt.value++
}

// Функция для чтения файла как потока и преобразования его в blob
async function fetchImageStream(key, url, callback) {
  try {
    const response = await fetch(url);
    const reader = response.body.getReader();
    const chunks = [];
    let done, value;

    while (true) {
      ({ done, value } = await reader.read());
      if (done) break;
      chunks.push(value);
    }

    const blob = new Blob(chunks);
    //const blobUrl = URL.createObjectURL(blob);

    callback(key, blob);

    //res_blob_url.value[key] = blobUrl

    //wt.value++
  } catch (error) {
    console.error("Ошибка при загрузке изображения:", error);
  }
}

// Функция для чтения файла как потока и преобразования его в blob
async function loadblob(key, url, callback) {
  console.log(`loadblob`, url)

  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  console.log(xhr)

  xhr.onload = function () {
    console.log('xhr.onload', xhr.status)
    if (xhr.status === 200) {
      const blob = xhr.response;
      //const blobUrl = URL.createObjectURL(blob)
      callback(key, blob);
      //setImages(blobUrl);
    } else {
      console.error(`Ошибка при загрузке изображения: ${xhr.statusText}`)
    }
  }
}

const sound_elem=reactive({})

function sound_play(id)
{
  if(Object.keys(res_blob_url.value).length>0) {
    if(muted.value!=true) {
      new Audio(res_blob_url.value[id]).play().catch((err)=>{console.log(err, id)});
    }
  }
}
provide('sound_play', sound_play)

async function load_res_bg(res_list)
{
  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    responseType: 'blob',
  }

  let blob_url_bg=res_list.blob_url_post;
  for (let key in blob_url_bg) {
    let resSrc = blob_url_bg[key]

    await axios.get(resSrc, config).then(
      (response) => {
        res_blob_url.value[key] = URL.createObjectURL(response.data)

        wt.value++
      }
    )
  }
}

async function load_res(res_list)
{
  flags.value.state='loading'

  res_cnt.value=Object.keys(res_list.blob_url).length+Object.keys(res_list.blob).length

  const config = {
    headers: {
      'X-Requested-With': 'XMLHttpRequest'
    },
    responseType: 'blob',
    /*onDownloadProgress: progressEvent => {
      //const total = parseFloat(progressEvent.currentTarget.responseHeaders['Content-Length'])
      //const current = progressEvent.currentTarget.response.length
      //let percentCompleted = Math.floor(current / total * 100)
      //console.log('completed: ', progressEvent.event.srcElement.responseURL, Math.round(progressEvent.progress*100))
    }*/
  }

  let blob_url=res_list.blob_url
  for (let key in blob_url) {
    let resSrc = blob_url[key]

    //await fetchImageStream(key, resSrc, adddImgUrl)
    //await loadblob(key, resSrc, adddImgUrl)
    axios.get(resSrc, config).then(
      (response) => {
        res_blob_url.value[key] = URL.createObjectURL(response.data)

        wt.value++
      }
    )
  }

  let blob_url_audio=res_list.blob_url_audio
  for (let key in blob_url_audio) {
    let resSrc = blob_url_audio[key]
    sound_elem[key] = null;

    axios.get(resSrc, config).then(
      (response) => {
        res_blob_url.value[key] = URL.createObjectURL(response.data)

        wt.value++
      }
    )
  }

  let blob=res_list.blob
  for (let key in blob) {
    let resSrc = blob[key]

    //await fetchImageStream(key, resSrc, adddImg)
    //await loadblob(key, resSrc, adddImg)
    axios.get(resSrc, config).then(
      (response) => {
        res_blob.value[key]=response.data

        wt.value++
      }
    )
  }

  setTimeout(load_res_bg, 3000, res_list)
}
provide('load_res', load_res)

function startapp()
{
  if (wt.value<res_cnt.value || res_cnt.value==null) {
    cnt_loaded.value=wt.value
    setTimeout(startapp, 1000)

    return
  }

  isload.value=true
}

const br_list=ref(false)

function mountedFn()
{
  if(process.env.VUE_APP_GAME=='') {
    br_list.value=true
  }

  startapp()

  const user_data=sessionStorage.getItem('user')

  if(params_get.login!=undefined && JSON.parse(user_data)!=null && JSON.parse(user_data).login!=params_get.login) {
    sessionStorage.removeItem('user');
  }

  zoomApp()
  window.addEventListener('resize', zoomApp)
  defaultLocale()
}
onMounted(mountedFn)
</script>

<style>
body {
  overflow: hidden !important;;
  background-color: black !important;
}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fleft {
  float: left;
}

@font-face {
  font-family: "Outlast";
  src: local("Outlast"), url(fonts/outlast.otf);
  font-weight: bold;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Outlast";
  src: local("Outlast"), url(fonts/outlast.otf);
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "Outlast";
  src: local("Outlast"), url(fonts/outlast.otf);
  font-weight: bold;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: local("AvenirNextCyr"), url(fonts/AvenirNextCyr-Regular.woff) format('woff');
  font-weight: normal;
  font-display: auto;
  font-style: normal;
}

@font-face {
  font-family: "AvenirNextCyr";
  src: local("AvenirNextCyr"), url(fonts/AvenirNextCyr-Bold.woff) format('woff');
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "Digital";
  src: local("Digital"), url(fonts/sf-digital-readout-4k.woff) format('woff');
  /*src: local("Digital"), url(fonts/AvenirNextCyr-Bold.woff) format('woff');*/
  /*src: local("Digital"), url(fonts/Digital7Mono.woff) format('woff');*/
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "Digital7";
  src: local("Digital7"), url(fonts/Digital7Mono.woff) format('woff');
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "7segment";
  src: local("7segment"), url(fonts/7segment.ttf);
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  src: local("Montserrat"),
  url(fonts/Montserrat-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: "Impact_mono";
  src: local("Impact_mono"),
  url(fonts/Impact_mono.ttf);
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "Bangers";
  src: local("Bangers"),
  url(fonts/Bangers-Regular.ttf);
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "BabyMonsta";
  src: local("BabyMonsta"),
  url(fonts/BabyMonsta.otf);
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

@font-face {
  font-family: "Oswald-Stencil";
  src: local("Oswald-Stencil"),
  url(fonts/Oswald-Stencil.ttf);
  font-weight: bold;
  font-style: auto;
  font-display: auto;
}

.brd {
  border: #ad1f24 1px solid;
}

.brdw {
  border: white 1px solid;
}

.blink_bonus {
  animation: blinker 2s linear infinite;
}
@keyframes blinker {
  20% {
    opacity: 0;
  }
}
</style>

<style scoped>
.ext_bg {
  position: absolute;
  width: 100%;
  z-index: -100;
}

.ext_bg img {
  width: 100%;
}

.mainWrapper {
  z-index: 10;
  text-align: center;
}

.wrapper {
  padding: 0;
  /*background-size: 93%;
  background-repeat: no-repeat;
  background-position: top left;*/
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  margin: auto;
}

.no-connection {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #eee;
  padding-top: 40px;
  font-size: 50px;
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.35);
  text-align: center;
  background-color: #034428;
  background-image: url(@/assets/img/no-connection.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transition: .7s;
  -o-transition: .7s;
  transition: .7s;
  z-index: 9999;
  opacity: 1;
}

.games_list {
  display: inline-block;
}
</style>
