<template>
  <div class="col-md-12" v-if="params_get.uid==undefined">
    <div class="card card-container">
      <img
        id="profile-img"
        src="../assets/logo.png"
        class="profile-img-card"
      />
      <div class="row">
        <div class="col">{{ $t('login.chooseLanguage') }}</div>
        <div class="col">
          <LanguageSwitcher></LanguageSwitcher>
        </div>
      </div>

      <div class="form-group">
        <label for="username">{{ $t('login.username') }}</label>
        <input v-model="username" name="username" type="text" class="form-control" />
      </div>
      <div class="form-group">
        <label for="password">{{ $t('login.password') }}</label>
        <input v-model="password" name="password" type="password" class="form-control" />
      </div>

      <div class="form-group">
        <button @click="handleLogin" class="btn btn-primary btn-block" :disabled="loading">
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>{{ $t('login.login') }}</span>
        </button>
      </div>

      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, inject, computed, provide, onMounted} from "vue";
import {useRouter} from 'vue-router'
import {useStore} from "vuex";
import LanguageSwitcher from "@/components/Public/LanguageSwitcher/LanguageSwitcher.vue";

const store = useStore()

const router = useRouter()

const params_get = inject('params_get')

const user_data = sessionStorage.getItem('user');

const username = ref('')
const password = ref('')

const loading = ref(false)
const message = ref("")

function loggedInFn()
{
  return store.state.auth.status.loggedIn;
}
const loggedIn = computed(loggedInFn)

function handleLogin(user)
{
  loading.value = true;

  if(username.value==='')
  {
    message.value = 'Invalid username or password!';
    loading.value = false;
    return;
  }

  store.dispatch("auth/login", user).then(
    (response) => {

      if(response.result)
      {
        if(process.env.VUE_APP_GAME!=undefined && process.env.VUE_APP_ROUTE!='/') {
          router.push("/"+process.env.VUE_APP_GAME);
        } else {
          router.push("/");
        }
      }
      else
      {
        loading.value = false;
        message.value = response.message;
      }
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  )
}

function mounted_fn()
{
  if(params_get.login!==undefined) {
    username.value = params_get.login

    let user_data = {};

    user_data.username = username.value

    if(params_get.password!==undefined) {
      password.value = params_get.password

      user_data.password = password.value
    }

    if(params_get.uid!==undefined) {
      user_data.uid = params_get.uid
      user_data.password = ''
    }

    handleLogin(user_data)
  }
}
onMounted(mounted_fn)
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 400px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.error-feedback {
  color: red;
}
</style>
