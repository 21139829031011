import axios from 'axios';
import md5 from 'js-md5'

const API_URL = process.env.VUE_APP_APIB+'auth/';

const config = {
  headers: {
    "Content-Type": "text/plain"
    // "Content-Type": "application/json; charset=utf-8"
  },
  withCredentials: true,
  crossOrigin: true
}

function parseGET() {
  // Обработка GET-запросов
  var tmp = new Array();    // два вспомогательных
  var tmp2 = new Array();  // массива
  var get = new Array();

  var url = location.search;	// строка GET запроса
  if(url != '') {
    tmp = (url.substr(1)).split('&');	// разделяем переменные
    for(var i=0; i < tmp.length; i++) {
      tmp2 = tmp[i].split('=');		// массив param будет содержать
      get[tmp2[0]] = tmp2[1];		// пары ключ(имя переменной)->значение
    }
  }

  return get;
}

const params_get=parseGET()

class AuthService {
  signin(user) {
    return axios
      .post(API_URL + 'signin/?login='+user.username, {
        login: user.username,
        password: md5(user.password)
      }, config)
      .then(response => {
        response.data.login=user.username
        sessionStorage.setItem('user', JSON.stringify(response.data));

        return response.data;
      });
  }

  tokenintegr(user) {
    return axios
      .post(API_URL + 'signintokenintgr/?login='+user.username, {
        login: user.username,
        token: user.uid,
        game_id: params_get.game_id
      }, config)
      .then(response => {
        response.data.login=user.username;
        let auth_data = response.data;
        auth_data.token = user.uid;
        sessionStorage.setItem('user', JSON.stringify(auth_data));

        return response.data;
      });
  }

  login(user) {
    if(user.uid!=undefined) {
      return this.tokenintegr(user);
    } else {
      return this.signin(user);
    }
  }

  logout() {
    sessionStorage.removeItem('user');

    return axios.post(API_URL + '/signout/index.php');
  }

  register(user) {
    return axios.post(API_URL + 'signup/', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();
